import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  inject,
  input,
  OnDestroy,
  output,
} from '@angular/core';
import { takeUntil, tap } from 'rxjs/operators';
import { fromEvent } from 'rxjs';
import { PlatformService } from '../../services/platform.service';
import { UntilDestroy } from 'ngx-unificator/decorator';
import { untilDestroyed } from 'ngx-unificator/rxjs';
import { LanguageService } from '../../services/language/language.service';

@UntilDestroy()
@Directive({
    selector: '[Accordion]',
    exportAs: 'accordionDirective',

})
export class AccordionDirective implements OnDestroy, AfterViewInit {
  private _el = inject(ElementRef);
  private _platform = inject(PlatformService);
  private _lang = inject(LanguageService);


  readonly closeAfterChangeLang = input<boolean>(undefined);
  readonly isOpenChange = output<boolean>();
  /**
   * Emits when component destroyed
   */
  private _alive$: EventEmitter<boolean> = new EventEmitter<boolean>();

  /**
   * Is accordion opened
   */
  private _open: boolean;

  /**
   * Accordion content element
   */
  private _content: HTMLElement;

  /**
   * Accordion toggling button
   */
  private _button: HTMLElement;

  /**
   * Listen accordion click
   */
  @HostListener('click') onClick() {
    this.toggle();
  }

  /**
   * Call once when component created
   * Init values and listeners
   */
  ngAfterViewInit() {
    this._button = this._el.nativeElement;
    // Getting the next sibling of the element and ignore html comment
    let nextSibling = this._el.nativeElement.nextSibling;
    while (nextSibling) {
      if (nextSibling.nodeType !== 8) {
        break;
      }
      nextSibling = nextSibling.nextSibling;
    }
    this._content = nextSibling;
    this._resolveInitialState();
    this._listenRecalcHeight();


    if (!!this.closeAfterChangeLang()) {
      this._lang.langChange$.pipe(
        untilDestroyed(this),
        tap(() => this.closeAfterChangeLang() ? this.close() : null )
      ).subscribe();
    }
  }

  /**
   * Call once when component destroyed
   * Clear listeners
   */
  ngOnDestroy() {
    this._alive$.next(false);
    this._alive$.complete();
  }

  /**
   * Get accordion state
   */
  get isOpen(): boolean {
    return this._open;
  }

  /**
   * Toggle accordion
   */
  toggle() {
    if (this._open) {
      this.close();
    } else {
      this.open();
    }
  }

  /**
   * Open accordion
   */
  open() {
    this._open = true;
    this._content.style.maxHeight = this._content.scrollHeight + 'px';
    this._button.classList.add('open');
    this._content.classList.add('open');
    this.isOpenChange.emit(this._open);
  }

  /**
   * Close accordion
   */
  close() {
    this._open = false;
    this._content.style.maxHeight = null;
    this._button.classList.remove('open');
    this._content.classList.remove('open');
    this.isOpenChange.emit(this._open);
  }

  /**
   * Resolve accordion initial state
   *
   * @private
   */
  private _resolveInitialState() {
    if (this._button.classList.contains('open')) {
      this.open();
    } else {
      this.close();
    }
  }

  /**
   * Update opened accordion height on 'AccordionResize' event
   *
   * @private
   */
  private _listenRecalcHeight() {
    if (this._platform.isBrowser) {
      fromEvent(window, 'AccordionResize').pipe(
        takeUntil(this._alive$)
      ).subscribe(() => {
        if (this._open) {
          this.open();
        }
      });
    }
  }

}
